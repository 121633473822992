import React, { useState, useEffect } from "react";

function Testimonials() {
  const testimonials = [
    {
      id: 1,
      text: "Swift and reliable service provided by the NRI Tax Solutions. Very happy.",
      name: "Alok Kumar",
      location: "Swindon",
      image: "images/person_1.jpg",
    },
    {
      id: 2,
      text: "Very responsive and matter dealt with professionally by the team.",
      name: "Kartik Reddy",
      location: "Bristol",
      image: "images/person_2.jpg",
    },
    // Add more testimonials here
  ];

  const [current, setCurrent] = useState(0);
  const length = testimonials.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev === length - 1 ? 0 : prev + 1));
    }, 5000); // Slide every 5 seconds

    return () => {
      clearInterval(interval); // Cleanup on unmount
    };
  }, [length]);

  return (
    <div className="container">
      <section className="ftco-section testimony-section">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 heading-section heading-section-white text-center">
            <h2 className="mb-4">What clients say about us?</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="carousel-testimony">
              <div
                className="testimonial-slider"
                style={{
                  transform: `translateX(-${current * 100}%)`,
                }}
              >
                {testimonials.map((testimonial, index) => (
                  <div className="testimonial-item" key={testimonial.id}>
                    <div className="testimony-wrap py-4">
                      <div className="text">
                        <span className="fa fa-quote-left"></span>
                        <p className="mb-4 pl-5">{testimonial.text}</p>
                        <div className="d-flex align-items-center">
                          <div
                            className="user-img"
                            style={{
                              backgroundImage: `url(${testimonial.image})`,
                            }}
                          ></div>
                          <div className="pl-3">
                            <p className="name">{testimonial.name}</p>
                            <span className="position">
                              {testimonial.location}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
