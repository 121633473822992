import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const SuccessModal = ({ showModal, message, isAdmin }) => {
  const [show, setShow] = useState(showModal);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    if (isAdmin) {
      navigate('/dashboard');
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>Success</Modal.Title>
          <Button variant="close" onClick={handleClose}>
            <i className="bi bi-x " style={{ fontSize: '1.5rem' }}></i> {/* Close icon */}
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center ">
          <div className="mb-3">
            <i className="bi bi-check-circle-fill text-success" style={{ fontSize: '3rem' }}></i>
          </div>
          <h5>{message}</h5>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="success" onClick={() => handleClose()}>
            Go to Dashboard
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

export default SuccessModal;
