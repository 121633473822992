import React, { useEffect, useState } from "react";
import api from "./api";
import { useParams } from "react-router-dom";
function CustomerPayment() {
  const [order, setOrder] = useState(null);
  const { orderId } = useParams();
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await api.get(`/user/get-payment/${orderId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching payment details:", error);
      }
    };

    fetchPaymentDetails();
  }, []);
  return (
    <>
      <section className="ftco-section">
        <div className="container">
          {order ? (<div className="creater-order table-1">
            <h2>CUSTOMER PAYMENT</h2>
            <form>
              <table
                className="order-table mb-4"
                cellPadding={1}
                cellSpacing={1}
              >
                <thead>
                  <tr>
                    <th>Order Number</th>
                    <th>{order?.orderId}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Description</td>
                    <td>{order?.description}</td>
                  </tr>
                  <tr>
                    <td>Invoice Reference</td>
                    <td>{order?.invoiceReference}</td>
                  </tr>
                  <tr>
                    <td>Total Fees</td>
                    <td>{order?.totalFee}</td>
                  </tr>
                  <tr>
                    <td>Advance Fee</td>
                    <td>{order?.advanceFee}</td>
                  </tr>
                </tbody>
              </table>
            </form>

            <section>
              <h5 className="font-weight-bold">Pay by Bank transfer </h5>
              <p>As supplied on our invoice</p>
              <p>Call us to arrange payment</p>
            </section>
          </div>) :
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
            </div>
          </div>}

        </div>
      </section>
    </>
  );
}

export default CustomerPayment;
