import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import api from '../components/api';

function ViewOrder() {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10; // Show only 10 orders per page
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const token = sessionStorage.getItem("token");
        const response = await api.get('/admin/allOrders', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchOrders();
  }, []);

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const currentOrders = orders.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(orders.length / recordsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <section className="ftco-section">
        {loading ? (<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-border text-primary" role="status">
          </div>
        </div>) : (
          <div className="">
            <div className="head d-flex justify-content-between">
              <h2>Admin Orders</h2>
              <NavLink to="/create-order" className="nav-link p-0" activeClassName="active">Create New Order</NavLink>
            </div>
            <form>
              <table className="order-table mb-4" cellPadding={1} cellSpacing={1}>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Customer Name</th>
                    <th>Customer ID</th>
                    <th>Description</th>
                    <th>Email ID</th>
                    <th>Contact Number</th>
                    <th>Status</th>
                    <th>Total Fee</th>
                    <th>Fees Paid</th>
                    <th>Payment Status</th>
                    <th>Documents Upload</th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map(order => (
                    <tr key={order._id}>
                      <td>
                        <NavLink to={`/order/${order.orderId}`}>
                          {order.orderId}
                        </NavLink>
                      </td>
                      {
                        (!order.customerData?._id && order.customerData?.name) ? <td>{order?.customerData?.name}</td> : <td>{order?.customerData?.firstName + " " + (order?.customerData?.lastName || "")}</td>
                      }
                      <td>{order.customerData?._id}</td>
                      <td>{order.service}</td>
                      <td>{order.customerData?.email}</td>
                      <td>{order.customerData?.contactNo || 'N/A'}</td>
                      <td>{order.status}</td>
                      <td>{order.totalFee}</td>
                      <td>{order.feesPaid}</td>
                      <td>{order.paymentStatus}</td>
                      <td>{order.documentsUploaded}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
            <div className="pagination">
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
          </div>)}
      </section>
    </>
  );
}

export default ViewOrder;
