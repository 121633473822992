import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import api from './api';
import OrderStatus from "./OrderStatus";
import { useLocation } from 'react-router-dom';

function CustomerHomepage({ onLogout, accountDetails }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      alreadyHavePAN: true, // Set the default value here
    },
  });
  const [orders, setOrders] = useState("")
  const [formData, setFormData] = useState("")
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const [successMessage, setSuccessMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const onSubmit = async (data) => {
    try {
      const response = await api.post(`/form/send-query/authenticated`, data, { headers: { Authorization: `Bearer ${token}` } });
      setFormData(response?.data)
      setSuccessMessage('Query submitted successfully!');
      reset();
    } catch (error) {
      console.error('Error submitting the query:', error);
      setErrorMessage('Failed to submit the query.');
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(`/user/get-orders`, { headers: { Authorization: `Bearer ${token}` } });
        setOrders(response?.data?.orders);
      } catch (error) {
        console.error('Error submitting the query:', error);
      }
    };

    fetchOrders();
  }, [location, formData]);


  return (
    <>
      <section id="customer-homepage" className='my-3'>
        <div className="container">
          {successMessage && <p class="alert alert-success" role="alert">{successMessage}</p>}
          {errorMessage && <p class="alert alert-danger" role="alert">{errorMessage}</p>}
          <form className="bg-light p-4 p-md-5 query-form mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 text-right"><p>You are logged in as {accountDetails?.user?.role}</p></div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>Select the service applicable to you and submit your query. We will respond back to you in 24 hours.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Select a Service</label>
                  <select className="form-control" {...register('service', { required: true })}>
                    <option value="">Select a Service</option>
                    <option value="Cash Gifts Received">Cash Gifts Received</option>
                    <option value="Sale of Property">Sale of Property</option>
                    <option value="Funds in Resident Savings">Funds in Resident Savings</option>
                    <option value="Tax Filing">Tax Filing</option>
                    <option value="PAN Card Services">PAN Card Services</option>
                  </select>
                  {errors.service && <p className="text-danger">Service is required.</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Already have PAN Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter PAN Number"
                  maxLength={10}
                  {...register('panNo', {
                    required: {
                      value: true,
                      message: 'PAN Number is required.',
                    },
                    pattern: {
                      value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                      message: 'Please enter a valid PAN Number (e.g., AAAAA9999A).',
                    },
                    minLength: {
                      value: 10,
                      message: 'PAN Number must be exactly 10 characters long.',
                    },
                    validate: {
                      noSpecialChars: value => /^[A-Z0-9]+$/.test(value) || 'PAN Number cannot contain special characters.',
                    },
                  })}
                  onChange={(e) => {
                    const filteredValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 10);
                    e.target.value = filteredValue; // Update the input value to filtered
                  }}
                />
                  {errors.panNo && <p className="text-danger">{errors.panNo.message}</p>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Query</label>
                  <textarea className="form-control" {...register('query', { required: true })}></textarea>
                  {errors.query && <p className="text-danger">Query is required.</p>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input type="submit" value="Submit" className="btn btn-primary py-3 px-5" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {orders?.length &&
        <section className=' container'>
          <section className='bg-light p-4 p-md-5 mx-auto'><OrderStatus orders={orders} /></section>

        </section>
      }

    </>


  );
}

export default CustomerHomepage;
