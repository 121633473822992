import React, { useState } from 'react';
import { useForm, watch } from 'react-hook-form';
import api from './api';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function Register({ onLogin }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const onSubmit = async (data) => {
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const address = ` ${data?.houseNumber || ""}  ${data?.streetName || ""} ${data?.town || ""} `;
      const { houseNumber, streetName, town, confirmPassword, ...registerData } = data; // Exclude confirmPassword from the data
      registerData.address = address;
      const registerResponse = await api.post(`/auth/register`, registerData);
      if (registerResponse?.data?.statusCode === 200) {
        setSuccessMessage("User Registered Successfully!")
        reset();
        const loginResponse = await api.post(`/auth/login`, {
          email: registerData.email,
          password: registerData.password,
        });
        if (loginResponse?.data?.statusCode) {
          onLogin(loginResponse?.data?.user, loginResponse?.data?.token)
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000)
        } else {
          setErrorMessage('Login failed.');
        }
      } else if (registerResponse?.data?.statusCode === 400) {
        setErrorMessage(registerResponse?.data?.message)
      }
      window.scrollTo(0, 0)
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <section className="ftco-section" id="services-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 heading-section mb-5">
            <h2 className="mb-4">Create An Account</h2>
            {successMessage && <p class="alert alert-success" role="alert">{"User Registered Successfully!"}</p>}
            {errorMessage && <p class="alert alert-danger" role="alert">{errorMessage}</p>}
            <form
              className="needs-validation bg-light p-md-5"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="form-row form-group">
                {/* Title Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="title">Title</label>
                  <select
                    className="form-control"
                    id="title"
                  >
                    <option value="">Select Title</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Dr.">Dr.</option>
                  </select>
                </div>

                {/* First Name Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="firstName">First name</label>
                  <sup className='text-danger'>*</sup>
                  {errors.firstName && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>
                      {errors.firstName.message}
                    </div>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="First name"
                    {...register('firstName', { required: 'First name is required' })}
                  />

                </div>

                {/* Last Name Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Last name"
                    {...register('lastName')}
                  />
                </div>

                {/* Email Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="email">Email Id</label>
                  <sup className='text-danger'>*</sup>
                  {errors.email && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>{errors.email.message}</div>
                  )}
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="info@mail.com"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Please provide a valid Email Id.',
                      },
                    })}
                  />

                </div>

                {/* Password Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="password">Password</label>
                  <sup className='text-danger'>*</sup>
                  {errors.password && (
                    <div className="invalid-feedback-error text-danger position-absolute" style={{ top: '0', right: '10px' }}>{errors.password.message}</div>
                  )}
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    placeholder="**********"
                    {...register('password', { required: 'Password is required' })}
                  />

                  <span
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                    style={{ position: 'absolute', top: '46px', right: '20px', cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                {/* Confirm Password Field */}
                <div className="col-md-6 mb-3 position-relative">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <sup className='text-danger'>*</sup>
                  { confirmPassword && password !== confirmPassword && (
                    <div
                      className="invalid-feedback-error text-danger position-absolute"
                      style={{ top: '0', right: '10px' }}
                    >
                      Passwords do not match
                    </div>
                  )}
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="form-control"
                    id="confirmPassword"
                    placeholder="**********"
                    {...register('confirmPassword', {
                      required: 'Please confirm your password',
                      validate: (value) =>
                        value === password || 'Passwords do not match',
                    })}
                  />

                  <span
                    className="password-toggle-icon"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{ position: 'absolute', top: '46px', right: '20px', cursor: 'pointer' }}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                {/* House Number Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="houseNumber">House Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder="Address"
                    {...register('houseNumber')}
                  />
                </div>

                {/* Street Name Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="streetName">Street Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="streetName"
                    placeholder="Street Name"
                    {...register('streetName')}

                  />
                </div>

                {/* City Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="city">Place/Town</label>
                  <input
                    type="text"
                    className="form-control"
                    id="place"
                    placeholder="Place/Town"
                    {...register('town')}

                  />
                </div>

                {/* State Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="state">State/City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    placeholder="State"
                    {...register('city')}
                  />
                </div>

                {/* Postal Code Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    placeholder="110001"
                    maxLength={10} // Set the maximum length (change as needed)
                    {...register('postalCode', {
                      pattern: {
                        value: /^\d+$/, // Regular expression to allow only digits
                        message: 'Postal code must contain only digits'
                      },
                      maxLength: {
                        value: 10, // Maximum length for the postal code
                        message: 'Postal code must be at most 10 digits long'
                      }
                    })}
                  />
                  {/* Optionally, display an error message if validation fails */}
                  {errors.postalCode && (
                    <div className="invalid-feedback-error text-danger">{errors.postalCode.message}</div>
                  )}
                </div>

                {/* Phone Number Field */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="contactNo">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactNo"
                    placeholder="Phone Number"
                    maxLength={15}
                    {...register('contactNo', {
                      pattern: {
                        value: /^\+?[0-9]{1,15}$/,
                        message: 'Please enter a valid phone number (up to 15 digits).',
                      },
                    })}
                    onKeyPress={(event) => {
                      // Allow digits and the first character to be '+'
                      if (!/[0-9]/.test(event.key) && (event.key !== '+' || event.target.value.length > 0)) {
                        event.preventDefault(); // Block non-numeric characters (except '+')
                      }
                    }}
                    onChange={(e) => {
                      const filteredValue = e.target.value.replace(/[^\d+]/g, '');
                      e.target.value = filteredValue; // Update the input value to filtered
                    }}
                  />
                  {errors.contactNo && (
                    <div className="invalid-feedback-error text-danger">{errors.contactNo.message}</div>
                  )}
                </div>

              </div>
              <button className="btn btn-primary px-5 py-3" type="submit">
                Create Account
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
