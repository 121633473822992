import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function Footer() {
  const [openSection, setOpenSection] = useState('about');

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? 'about' : section);
  };



  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <footer className="ftco-footer ftco-section d-none d-md-block">
        <div className="container">
          <div className="row mb-5">
            {/* First Section */}
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">
                  Let's talk about
                </h2>
                <div>
                  <p>We help NRIs make their funds available to them when and where they need it and be fully tax compliant.</p>
                  <p><strong>Ready to experience our services?</strong></p>
                  <p><NavLink className="btn btn-white" to="/register" onClick={scrollToTop} onTouchEnd={scrollToTop}>Register Now</NavLink></p>
                </div>
              </div>
            </div>

            {/* Links Section */}
            <div className="col-md">
              <div className="ftco-footer-widget mb-4 ml-md-4">
                <h2 className="ftco-heading-2">
                  Links
                </h2>
                <ul className="list-unstyled">
                  <li><NavLink exact to="/" className="nav-link p-0" activeClassName="active" onClick={scrollToTop} onTouchEnd={scrollToTop}>Home</NavLink></li>
                  <li><NavLink to="/about-us" className="nav-link p-0" activeClassName="active" onClick={scrollToTop} onTouchEnd={scrollToTop}>About Us</NavLink></li>
                  <li><NavLink className="nav-link p-0" to="/pan-card-services" onClick={scrollToTop} onTouchEnd={scrollToTop}>Solutions</NavLink></li>
                  <li><NavLink to="/contact-us" className="nav-link p-0" activeClassName="active" onClick={scrollToTop} onTouchEnd={scrollToTop}>Contact Us</NavLink></li>
                </ul>
              </div>
            </div>

            {/* Services Section */}
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">
                  Solutions
                </h2>
                <ul className="list-unstyled">
                  <li><NavLink className="nav-link p-0" to="/pan-card-services" onClick={scrollToTop} onTouchEnd={scrollToTop}>PAN Card Services</NavLink></li>
                  <li><NavLink className="nav-link p-0" to="/digital-signature-certificate" onClick={scrollToTop} onTouchEnd={scrollToTop}>Digital Signature Certificate</NavLink></li>
                  <li><NavLink className="nav-link p-0" to="/tax-filing" onClick={scrollToTop} onTouchEnd={scrollToTop}>Tax Filing</NavLink></li>
                  <li><NavLink className="nav-link p-0" to="/cash-gifts" onClick={scrollToTop} onTouchEnd={scrollToTop}>Cash Gifts</NavLink></li>
                  <li><NavLink className="nav-link p-0" to="/capital-gains" onClick={scrollToTop} onTouchEnd={scrollToTop}>Capital Gains</NavLink></li>
                  <li><NavLink className="nav-link p-0" to="/funds-saving-account" onClick={scrollToTop} onTouchEnd={scrollToTop}>Funds in Resident Savings Account</NavLink></li>
                </ul>
              </div>
            </div>

            {/* Contact Section */}
            <div className="col-md">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">
                  Have Questions?
                </h2>
                <div>
                  <div className="block-23 mb-3">
                    <ul>
                      <li><span className="icon fa fa-map marker"></span><span className="text">Bristol, United Kingdom</span></li>
                      <li><a href="tel:+23923929210" onClick={scrollToTop} onTouchEnd={scrollToTop}><span className="icon fa fa-phone"></span><span className="text"> +447546 883119</span></a></li>
                      <li><a href="mailto:operations@nritaxsolutions.com" onClick={scrollToTop} onTouchEnd={scrollToTop}><span className="icon fa fa-paper-plane pr-4"></span><span className="text">operations@nritaxsolutions.com</span></a></li>
                    </ul>
                  </div>
                  <ul className="ftco-footer-social list-unstyled mt-2">
                    <li className="ftco-animate"><a href="#"><span className="fa fa-twitter" onClick={scrollToTop} onTouchEnd={scrollToTop}></span></a></li>
                    <li className="ftco-animate"><a href="#"><span className="fa fa-facebook" onClick={scrollToTop} onTouchEnd={scrollToTop}></span></a></li>
                    <li className="ftco-animate"><a href="#"><span className="fa fa-instagram" onClick={scrollToTop} onTouchEnd={scrollToTop}></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Bottom Section */}
          <div className="row">
            <div className="col-md-12 text-center">
              <p>&copy; {new Date().getFullYear()} Copyright protected by SiTra Solutions Limited. NRI Tax Solutions is a trade name registered by Sitra Solutions Ltd.</p>
            </div>
          </div>
        </div>
      </footer>



      <footer className="ftco-footer ftco-section d-block d-md-none">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md">
              <Accordion defaultActiveKey={['0']}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Let's talk about
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <p>We help NRIs make their funds available to them when and where they need it and be fully tax compliant.</p>
                      <p>Ready to experience our services?</p>
                      <p><NavLink className="btn btn-white" to="/register" onClick={scrollToTop} onTouchEnd={scrollToTop}>Register Now</NavLink></p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Links</Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-unstyled">
                      <li><NavLink exact to="/" className="nav-link p-0" activeClassName="active" onClick={scrollToTop} onTouchEnd={scrollToTop}>Home</NavLink></li>
                      <li><NavLink to="/about-us" className="nav-link p-0" activeClassName="active" onClick={scrollToTop} onTouchEnd={scrollToTop}>About Us</NavLink></li>
                      <li><NavLink className="nav-link p-0" to="/pan-card-services" onClick={scrollToTop} onTouchEnd={scrollToTop}>Solutions</NavLink></li>
                      <li><NavLink to="/contact-us" className="nav-link p-0" activeClassName="active" onClick={scrollToTop} onTouchEnd={scrollToTop}>Contact Us</NavLink></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Solutions</Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-unstyled">
                      <li><NavLink className="nav-link p-0" to="/pan-card-services" onClick={scrollToTop} onTouchEnd={scrollToTop}>PAN Card Services</NavLink></li>
                      <li><NavLink className="nav-link p-0" to="/digital-signature-certificate" onClick={scrollToTop} onTouchEnd={scrollToTop}>Digital Signature Certificate</NavLink></li>
                      <li><NavLink className="nav-link p-0" to="/tax-filing" onClick={scrollToTop} onTouchEnd={scrollToTop}>Tax Filing</NavLink></li>
                      <li><NavLink className="nav-link p-0" to="/cash-gifts" onClick={scrollToTop} onTouchEnd={scrollToTop}>Cash Gifts</NavLink></li>
                      <li><NavLink className="nav-link p-0" to="/capital-gains" onClick={scrollToTop} onTouchEnd={scrollToTop}>Capital Gains</NavLink></li>
                      <li><NavLink className="nav-link p-0" to="/funds-saving-account" onClick={scrollToTop} onTouchEnd={scrollToTop}>Funds in Resident Savings Account</NavLink></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Have Questions?</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="block-23 mb-3">
                        <ul>
                          <li><span className="icon fa fa-map marker"></span><span className="text">Bristol, United Kingdom</span></li>
                          <li><a href="tel:+23923929210" onClick={scrollToTop} onTouchEnd={scrollToTop}><span className="icon fa fa-phone"></span><span className="text"> +447546 883119</span></a></li>
                          <li><a href="mailto:operations@nritaxsolutions.com" onClick={scrollToTop} onTouchEnd={scrollToTop}><span className="icon fa fa-paper-plane pr-4"></span><span className="text">operations@nritaxsolutions.com</span></a></li>
                        </ul>
                      </div>
                      <ul className="ftco-footer-social list-unstyled mt-2">
                        <li className="ftco-animate"><a href="#"><span className="fa fa-twitter" onClick={scrollToTop} onTouchEnd={scrollToTop}></span></a></li>
                        <li className="ftco-animate"><a href="#"><span className="fa fa-facebook" onClick={scrollToTop} onTouchEnd={scrollToTop}></span></a></li>
                        <li className="ftco-animate"><a href="#"><span className="fa fa-instagram" onClick={scrollToTop} onTouchEnd={scrollToTop}></span></a></li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <p>&copy; {new Date().getFullYear()} Copyright protected by SiTra Solutions Limited. NRI Tax Solutions is a trade name registered by Sitra Solutions Ltd.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
