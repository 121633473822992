import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from 'react-router-dom';
import api from './api';
import SuccessModal from "./successModal";
import ErrorModal from "./errorModal";

function UpdateOrder() {
  const { orderId } = useParams();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false)
  const [showErrorModal, setErrorModal] = useState(false)
  const [orderData, setOrderData] = useState({
    orderId: '',
    queryReference: '',
    customerAddress: '',
    description: '',
    status: 'Pending',
    totalFee: 0,
    feesPaid: 0,
    feesDue: 0,
    documentsUploaded: 'No',
    invoiceReference: '',
    paymentStatus: '',
    advanceFee: '',
    attachedDocuments: [],
    customerData: {
      firstName: '',
      lastName: '',
      name: '',
      _id: '',
      address: '',
      contactNo: '',
      email: '',
      city: '',
      postalCode: ''
    }
  });
  const [errors, setErrors] = useState({
    contactNo: '',
    email: '',
  });

  const fetchOrderData = useCallback(async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await api.get(`/admin/getOrder/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;
      const totalFee = parseInt(data.totalFee, 10) || 0;
      const feesPaid = parseInt(data.feesPaid, 10) || 0;
      const feesDue = totalFee - feesPaid;

      setOrderData(prevState => ({
        ...prevState,
        ...data,
        feesDue,
        customerData: { ...prevState.customerData, ...data.customerData }
      }));
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  }, [orderId]);

  useEffect(() => {
    fetchOrderData();
  }, [fetchOrderData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'totalFee' || name === 'feesPaid') {
      newValue = parseInt(value, 10) || 0;
    }

    setOrderData(prevState => {
      let updatedState = { ...prevState };

      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        updatedState = {
          ...updatedState,
          [parent]: {
            ...updatedState[parent],
            [child]: newValue
          }
        };
      } else {
        updatedState[name] = newValue;
      }

      if (name === 'totalFee' || name === 'feesPaid') {
        const totalFee = name === 'totalFee' ? newValue : updatedState.totalFee;
        const feesPaid = name === 'feesPaid' ? newValue : updatedState.feesPaid;
        updatedState.feesDue = totalFee - feesPaid;
      }
      if (updatedState.advanceFee > updatedState.totalFee) {
        setErrors(prevErrors => ({
          ...prevErrors,
          advanceFee: 'Advance Fee cannot be greater than Total Fee.'
        }));
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          advanceFee: '' // Clear error if valid
        }));
      }

      return updatedState;
    });
    validateInputs(name, value);
    setIsFormChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasErrors = Object.values(errors).some(error => error !== '');
    if (hasErrors) {
      setErrorModal(true);
      return;
    }
    try {
      const token = sessionStorage.getItem("token");
      const response = await api.put(`/admin/update-form/${orderId}`, orderData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response?.data?.statusCode) {
        setSuccessModal(true)
      } else {
        setErrorModal(true)
      }
      await fetchOrderData();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };
  const validateInputs = (name, value) => {
    let errorMsg = '';

    if (name === 'customerData.contactNo') {
      const phoneRegex = /^(?:\+?[0-9]{1,3})?[0-9]{10,15}$/;

      if (!phoneRegex.test(value)) {
        errorMsg = 'Please enter a valid phone number.';
      }
    }

    if (name === 'customerData.email') {
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value) {
        errorMsg = 'Email is required.';
      } else if (!emailRegex.test(value)) {
        errorMsg = 'Please enter a valid email address.';
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name.split('.')[1]]: errorMsg,
    }));
  };
  return (
    <section className="ftco-section">
      <div className="container">
        <div className="creater-order table-1">
          <h2>Update Order</h2>
          <form onSubmit={handleSubmit}>
            <table className="order-table mb-4" cellPadding={1} cellSpacing={1}>
              <tbody>
                <tr>
                  <td>Order ID</td>
                  <td>{orderData.orderId}</td>
                </tr>
                {orderData.customerData.name ?
                  <tr>
                    <td>Customer Name</td>
                    <td><input type="text" name="customerData.name" value={orderData.customerData.name || ''} onChange={handleChange} /></td>
                  </tr> :
                  <>
                    <tr>
                      <td>First Name</td>
                      <td><input type="text" name="customerData.firstName" value={orderData.customerData.firstName || ''} onChange={handleChange} /></td>
                    </tr>
                    <tr>
                      <td>Last Name</td>
                      <td><input type="text" name="customerData.lastName" value={orderData.customerData.lastName || ''} onChange={handleChange} /></td>
                    </tr>
                  </>}
                <tr>
                  <td>Query Reference</td>
                  <td><input type="text" name="queryReference" value={orderData.queryReference || ''} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Customer ID</td>
                  <td>{orderData.customerData._id}</td>
                </tr>
                <tr>
                  <td>Customer Address</td>
                  <td><input type="text" name="customerAddress" value={orderData?.customerAddress || orderData?.customerData?.address || ''} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Customer Contact Number</td>
                  <td>
                    <input
                      type="text"
                      name="customerData.contactNo"
                      value={orderData?.customerData?.contactNo || ''}
                      onChange={(e) => {
                        const filteredValue = e.target.value.replace(/[^0-9+]/g, '').slice(0, 15);
                        e.target.value = filteredValue;
                        handleChange(e);
                      }}
                      maxLength="15"
                    />
                    {errors.contactNo && <div style={{ color: 'red' }}>{errors.contactNo}</div>}
                  </td>
                </tr>
                <tr>
                  <td>Customer Email ID</td>
                  <td><input type="text" name="customerData.email" value={orderData?.customerData?.email || ''} onChange={handleChange} />{errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}</td>
                </tr>
                <tr>
                  <td>Customer City</td>
                  <td><input type="text" name="customerData.city" value={orderData?.customerData?.city || ''} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Customer Postal Code</td>
                  <td><input type="text" name="customerData.postalCode" value={orderData?.customerData?.postalCode || ''} onChange={handleChange} onKeyPress={(e) => {
                    // Prevent non-numeric characters
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }} /></td>
                </tr>
                <tr>
                  <td>Service</td>
                  <td><input type="text" name="service" value={orderData?.service} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td><input type="text" name="description" value={orderData?.query} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <select name="status" value={orderData.status || ''} onChange={handleChange}>
                      <option value="Pending">Pending</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Complete">Complete</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Advance Fee</td>
                  <td><input type="text" name="advanceFee" value={orderData?.advanceFee || ''} onChange={handleChange} />{errors.advanceFee && <div className="text-danger error">{errors.advanceFee}</div>}</td>
                </tr>
                <tr>
                  <td>Total Fee</td>
                  <td><input type="text" name="totalFee" value={orderData?.totalFee || ''} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Fees Paid</td>
                  <td><input type="text" name="feesPaid" value={orderData?.feesPaid || ''} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Fees Due</td>
                  <td><input type="text" name="feesDue" value={orderData?.feesDue || ''} readOnly /></td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td><input type="text" name="paymentStatus" value={orderData?.paymentStatus || ''} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Documents Uploaded</td>
                  <td>
                    <select name="documentsUploaded" value={orderData?.documentsUploaded || ''} onChange={handleChange}>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Invoice Reference</td>
                  <td><input type="text" name="invoiceReference" value={orderData?.invoiceReference || ''} onChange={handleChange} /></td>
                </tr>
              </tbody>
            </table>
            <section className="">
              <button type="submit" className="btn btn-success py-3 px-4 mr-4" disabled={!isFormChanged}>Update</button>
              <Link to="/dashboard" className="btn btn-primary py-3 px-4">Go to Dashboard</Link>
            </section>
          </form>
          {showSuccessModal && <SuccessModal showModal={showSuccessModal} message={"Order Updated Successfully!"} isAdmin={true} />}
          {showErrorModal && <ErrorModal showModal={showErrorModal} />}
        </div>
      </div>
    </section>
  );
}

export default UpdateOrder;