import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import api from './api';

function ResetPassword() {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [token, setToken] = useState(""); 
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for success message
  const [isFormVisible, setIsFormVisible] = useState(true); // State for form visibility

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    setToken(tokenFromUrl); // Set the extracted token to the state
  }, []);

  const onSubmit = async (data) => {
    try {
      // Make the API call to reset the password
      const payload = {
        newPassword: data.newPassword, // Send only newPassword
        token, // Include the token from the URL
      };
      const response = await api.post(`/auth/reset-password`, payload);

      // Check the status code and update state accordingly
      if (response?.data?.statusCode === 200) {
        setSuccessMessage(response?.data?.message); // Set success message
        setIsFormVisible(false); // Hide the form
      } else {
        throw new Error('Server error'); // Trigger catch block for other status codes
      }
    } catch (error) {
      console.error('Error resetting the password:', error);
      setErrorMessage('Failed to reset the password. Please try again later.');
    }
  };

  return (
    <section className="ftco-section contact-section ftco-no-pb" id="reset-password-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {successMessage && <p className="text-success text-center">{successMessage}</p>}
            {errorMessage && <p class="alert alert-danger" role="alert">{errorMessage}</p>}
            {isFormVisible ? (
              <form onSubmit={handleSubmit(onSubmit)} className="bg-light p-4 p-md-5 contact-form">
                <h4 className="text-center">Reset Password</h4>

                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        {...register("newPassword", { 
                          required: "New Password is required",
                          minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters long"
                          }
                        })}
                      />
                      {errors.newPassword && <p className="text-danger">{errors.newPassword.message}</p>}
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        {...register("confirmPassword", { 
                          required: "Confirm Password is required",
                          validate: (value) => value === watch('newPassword') || "Passwords do not match"
                        })}
                      />
                      {errors.confirmPassword && <p className="text-danger">{errors.confirmPassword.message}</p>}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group text-center">
                      <input
                        type="submit"
                        value="Reset Password"
                        className="btn btn-primary py-3 px-5"
                      />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <h5>Your password has been reset successfully!</h5>
                <p>You can now log in with your new password.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
